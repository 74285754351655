import React from 'react';
import {BsInstagram} from 'react-icons/bs';
import {FaFacebookSquare} from 'react-icons/fa';
import {FaYelp} from 'react-icons/fa';
import pauaSalon from '../assets/pauaSalon.jpg';

const About = () => {
  return (
    <div name='about' className=' text-center p-10'>
        <h1 className='pb-3 text-4xl font-body01 font-bold text-stone-400 '>About Us</h1>
      
      <div className='py-5 lg:px-4  '> 
        <div className='flex justify-center aspect-w-4 aspect-h-1'>
            <img src={pauaSalon} alt="/" className='object-cover shadow-lg rounded-lg' />
        </div> 
      </div>
          
        {/* <div className='flex justify-center'> */}
            {/* <img src={pauaSalon} alt="/" className=' py-4 h-25 w-25' /> */}
          
          
        {/* </div> */}
      
        <p className=" text-stone-500 text-2xl font-fontKalam font-light-light-300 py-2">Paua Beauty Lounge locates close by Nort Beach and Russian Hill.</p>
        <p className=" text-stone-500 text-2xl font-fontKalam font-light-light-300">The salon is known for providing top notch customer care, high quality products and services.</p>
        <p className=" text-stone-500 text-2xl font-fontKalam font-light-light-300 py-2">Paua offers a wide variety of top products for your selection, and a long list of services for every occasion.</p>
        <p className="text-stone-500 text-2xl font-fontKalam font-light-light-300">We promise to leave you feeling relaxed, rejuvenated, and renewed. </p>

        <p className="py-2 text-stone-500 text-2xl font-fontKalam font-light-300">We look forward to serving you! </p>

        {/* button for reservation */}
        
        <div className='md:flex justify-center'>
          <a href="https://booksy.com/en-us/387858_paua-beauty-lounge_nail-salon_134715_san-francisco">
            <button className=' text-xl font-body01 border bg-stone-400 border-stone-300 px-8 py-3 rounded-lg'>Reservation</button>
          </a>
        </div>
        
        
        {/* social media icons */}

        <p className="pt-6 text-stone-500 text-2xl font-fontKalam font-light-300">Please visit us at: </p>
       

        <div className='text-5xl flex justify-center gap-16 py-3'>
          < a href="https://www.instagram.com/pauabeautylounge/?hl=en">
           < BsInstagram className='fill-stone-400'/>
           </a>

           < a href="https://www.facebook.com/pauabeautylounge/">
           < FaFacebookSquare className='fill-stone-400'/>
           </a>
            
           < a href="https://www.yelp.com/biz/paua-beauty-lounge-san-francisco-2">
           < FaYelp className='fill-stone-400'/>
           </a>
           
        </div>

        {/* <div className='flex justify-center'>
          <img src={pauaSalon} alt="paua beauty salon" className=' py-4 h-25 w-25' />
        </div> */}
    </div>
  
  )
}

export default About