import React from 'react';
import {useState, useEffect} from 'react';
import {MenuIcon, XIcon} from '@heroicons/react/outline' ;
import pauaIcon from '../assets/pauaIcon.png';
import {Link} from 'react-scroll';

const Navbar = () => {

  const [nav, setNav] = useState(false);

  const handleClick = () => setNav(!nav);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (

    // navbar for full size screen 
    <div className='w-screen h-[100px] z-20 bg-slate-100 fixed drop-shadow-md'> 
      <div className='px-2 flex justify-evenely  items-center w-full h-full'>
        <div className='flex items-center'>

          <img src={pauaIcon} className='lg:h-[5%] lg:w-[5%] h-[8%] w-[8%] rounded-full  mr-2' alt="paua beauty icon" onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }}/>

          {/* <img src={pauaIcon} className='h-[5%] w-[5%] rounded-full p-2 mr-2 ' alt="paua beauty icon" /> */}
          <h1 className='text-2xl font-fontQS font-bold mr-2 sm:text-4xl' >Paua Beauty</h1>
  
            <ul className='hidden lg:flex mt-2'>
            <Link to="home" smooth={true} duration={300}><li>Home</li></Link>
              <Link to="about" smooth={true} offset={-100} duration={300}><li>About</li></Link>
              <Link to="services" smooth={true} offset={-100} duration={300}><li>Services</li></Link>
              <Link to="location" smooth={true} offset={-100} duration={300}><li>Location</li></Link>
              <Link to="contact" smooth={true} offset={-50} duration={300}><li>Contact</li></Link> 
              <a href="https://booksy.com/en-us/387858_paua-beauty-lounge_nail-salon_134715_san-francisco" className='py-2'>
                <button className='ml-2 w-24 h-10 bg-stone-800 border-stone-800 rounded-full'>Book</button>
              </a>    
            </ul>
            {/* <div className='hidden md:flex px-6'>
              <a href="https://booksy.com/en-us/387858_paua-beauty-lounge_nail-salon_134715_san-francisco">
                <button className='w-24 h-10 bg-stone-800 border-stone-800 rounded-full'>Book</button>
              </a>
            </div> */}
           
        </div>

        {/* <div className='hidden lg:flex pr-4 '>
          <button className='border-none bg-transparent text-gray-500 mr-3 h-12 w-20'>Sign In</button>
          <button className='h-12 w-28 rounded-lg' >Sign Up</button>
        </div> */}

         {/* menu icon */}
        <div className='lg:hidden' onClick={handleClick}>
          {!nav ? <MenuIcon className=' mr-8 w-8 '/> : <XIcon className='mr-8 w-8'/>}
          
        </div>
      </div>
      
      {/* condense the navbar for smaller screen size, the setting icon will dropdown when clicked*/}
      <ul className={!nav ? 'hidden':'absolute bg-zinc-200 w-full px-8 text-xl font-fontQs font-bold text-gray-500'}>
          <li className ='py-4 border-b-2 border-zinc-300 w-full'>
            <Link to="home" smooth={true} duration={300}><li>Home</li></Link>
          </li>
          <li className ='border-b-2 border-zinc-300 w-full'>
            <Link to="about" smooth={true} offset={20} duration={300}><li> About</li></Link>
          </li>
          <li className ='border-b-2 border-zinc-300 w-full'>
             <Link to="services" smooth={true} offset={20} duration={300}><li>Services</li></Link>
          </li>
          <li className ='border-b-2 border-zinc-300 w-full'>
            <Link to="location" smooth={true} offset={20} duration={300}><li>Location</li></Link>
          </li>
          <li className ='border-b-2 border-zinc-300 w-full'>
            <Link to="contact" smooth={true} offset={20} duration={300}><li>Contact</li></Link>
          </li>
          
          <a href="https://booksy.com/en-us/387858_paua-beauty-lounge_nail-salon_134715_san-francisco" className='no-underline' >
            <div className= 'my-4 flex flex-col'>
            <button className='px-8 py-3 bg-stone-800 border-stone-800 rounded-full'>Book</button>
            </div>
          </a> 

       
         
          {/* <div className='my-4 flex flex-col'>
            <button className='bg-transparent text-gray-500 px-8 py-3 mb-4'>Sign In</button>
            <button className='px-8 py-3'>Sign Up</button>
          </div> */}
      </ul>
      
    </div>
  )
}

export default Navbar