export const menu1 = [
    {
        "serviceName": "Popular Package",
        "links": [
            {"name" : "Paua Gel Manicure","price": "$45.00 (50 min)"},
            {"name" : "Dip Powder","price": "$60.00 (1 hr)"},
            {"name" : "Gel Mani and Deluxe Pedi Package","price": "$80.00 (1 hr 30 min)"},
            {"name" : "Gel Mani and Gel Pedi","price": "$90.00 (1 hr 30 min)"}
        ]
        
    }
]

export const menu2 = [ 
    {
        "serviceName": "Manicure",
        "links": [
            {"name" : "Paua Manicure","price": "$28.00 (25 min)"},
            {"name" : "Paua Gel Manicure","price": "$45.00 (45 min)"},
            {"name" : "Paua DIP Manicure","price": "$60.00 (1 hr)"},
            {"name" : "Paua Mani/Pedi ","price": "$60.00 (70 min)"},
            {"name" : "Paua Full Set Mani","price": "$70.00 up (80 min)"},
            {"name" : "Paua Gel Mani/ Reg Pedi","price": "$80.00 (80 min)"},
            {"name" : "Gel X Mani","price": "$80.00 (75 min)"},
            {"name" : "Paua Gel Mani/Pedi","price": "$90.00 (1 hr 30 min)"},
            {"name" : "Deluxe add-on","price": "$15.00 (each)"}
          
        ]

    }

]

export const menu3 = [
    {
        "serviceName": "Pedicure",
        "links": [
            {"name" : "Paua Pedicure","price": "$38.00 (45 min)"},
            {"name" : "Paua Gel Pedicure ","price": "$50.00 (50 min)"},
            {"name" : "Paua Mani/Pedi","price": "$60.00 (70 min)"},
            {"name" : "Paua Gel Mani/ Reg Pedi","price": "$80.00 (80 min)"},
            {"name" : "Paua Gel Mani/Pedi","price": "$90.00 (1 hr 30 min)"},
            {"name" : "Deluxe add-on","price": "$15.00 (each)"},
        ]
        
    }
]

export const menu4 = [
    {
        "serviceName": "Additional Service",
        "links": [
            {"name" : "French Mani/Pedi","price": "$10.00 each (10 min)"},
            {"name" : "Gel/ DIP take off ","price": "$10.00 (10 min)"},
            {"name" : "Parafin Hands/Feet","price": "$15.00 each (10 min)"},
            {"name" : "Callus Remove","price": "$15.00 (5 min)"},
            {"name" : "Polish Change on Hands","price": "$20.00 (15 min)"},
            {"name" : "Polish Change on Feet","price": "$28.00 (15 min)"},
            {"name" : "Nails Art","price": "$5.00 + each"},
            {"name" : "Meltaway Massage","price": " $25.00/ $35.00 (15-20 min)"}
        ]
        
    }
]

export const menu5 = [
    {
        "serviceName": "Facial Hair Waxing",
        "links": [
            {"name" : "Lip","price": "$12.00 (5 min)"},
            {"name" : "Chin","price": "$15.00 (10 min)"},
            {"name" : "Cheek","price": "$15.00 (15 min)"},
            {"name" : "Ears","price": "$15.00 (15 min)"},
            {"name" : "Brow","price": "$20.00 (15 min)"},
            {"name" : "Face","price": "$48.00 (35 min)"}
        ]
        
    }
]

export const menu6 = [
    {
        "serviceName": "Body Waxing",
        "links": [
            {"name" : "Underarms","price": "$32.00 (15 min)"},
            {"name" : "Chest & Stomach ","price": "$58.00 (35 min)"},
            {"name" : "Bikini/Extended","price": "$45.00/$55.00 (25/35 min)"},
            {"name" : "Brazilian/Mainternance","price": "$68.00/$55.00 (35/30 min)"},
            {"name" : "Half/Full Arms","price": "$35.00/$50.00 (25/35 min)"},
            {"name" : "Half/Full Back","price": "$40.00/$60.00 (25/40 min)"},
            {"name" : "Half/Full Legs","price": "$48.00/$68.00 (30/40 min)"}
        ]      
    }
]

export const menu7 = [
    {
        "serviceName": "Facial",
        "links": [
            {"name" : "Express Facial ","price": "$65.00 (35 min)"},
            {"name" : "Microdemabrasion Facial ","price": "$150.00/$180.00 (60/90 min)"},
          //  {"name" : "MicroChanneling Facial","price": "$350.00 (75 min)"},
            {"name" : "LED Light Therapy add-on ","price": "$50.00 (30 min)"}
        ]
        
    }
]

export const menu8 = [
    {
        "serviceName": "Microblading",
        "links": [
            {"name" : "First Microblading + 1 touch up","price": "$580.00"},
            {"name" : "Maintenance touch up ","price": "$180.00"}
        ]
        
    }
]

export const menu9 = [
    {
        "serviceName": "Lashes Extension",
        "links": [
            {"name" : "Full Set","price": "$185.00 (200+ lashes)"},
            {"name" : "Trial Set ","price": "$145.00 (120 lashes)"}
        ]
        
    }
]

