import Navbar from './components/Navbar'
// import './App.css';
import Dashboard from './components/Dashboard';
import Services from './components/Services';
import Mission from './components/Mission';
import About from './components/About';
import GoogleMap from './components/GoogleMap';
import Menu from './components/Menu/Menu';
import Footer from './components/Footer';
import Gallery from './components/Gallery/test.jsx';



function App(){

  return (
   <>
    <Navbar />
    <Dashboard /> 
    <Services /> 
    <Mission />
    <About />
    <Menu /> 
    <Gallery />
    <GoogleMap />
    <Footer />
  
   </>
  );

}

export default App;
