import React from 'react';
import manipedipic from '../assets/Manicure-and-Pedicure.jpg';
import facialPic from '../assets/facialspa.jpg';
import pediPic from '../assets/pedicure_01.jpg';
import {Link } from 'react-scroll';


const Services = () => {
  return (
    <div className='max-w-[1140px] m-auto w-full md:flex mt-[-70px]'>

        <div className='relative p-4'>
          <Link to="mani&pedi" smooth={true} offset={-50} duration={300}>
            <h3 className='absolute z-10 top-[50%] left-[30%] transate-x-[-50%] translate-y-[10%] text-stone-500 text-2xl font-fontKalam italic font-bold'>Mani & Pedi</h3>
            <img className='w-full h-full object-cover relative border-solid border-4 border-white shadow-xl' src={manipedipic} alt="/" />
          </Link>
        </div>


        <div className='relative p-4'>
          <Link to="waxing" smooth={true} offset={-50} duration={300}>
            <h3 className='absolute z-10 top-[50%] left-[38%] transate-x-[-50%] translate-y-[10%] text-stone-200 
            text-2xl font-fontKalam italic font-bold'>Waxing</h3>
            <img className='w-full h-full object-cover relative border-solid border-4 border-white shadow-xl' src={pediPic} alt="/" />
            </Link>
        </div>
        
        <div className='relative p-4'>
          <Link to="otherService" smooth={true} offset={-50} duration={300}>
            <h3 className='absolute z-10 top-[50%] left-[30%] transate-x-[-50%] translate-y-[10%] text-stone-200 text-2xl font-fontKalam italic font-bold'>Facial & Other</h3>
            <img class="w-full h-full object-cover relative border-solid border-4 border-white shadow-xl" src={facialPic}  alt="/" />
          </Link>
        </div>
        
    </div>
  )
}

export default Services