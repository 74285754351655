import React from 'react';
//import iconImg from '../assets/pauaIcon.png';
import iconImg2 from '../assets/PauaBeauty.png';


const GoogleMap =() =>{
  
  return (
    <div name='location' className='bg-bgColor_01 bg-cover bg-center'>
      <div className='text-center '>
        
      {/* <section className="py-8 container mx-auto flex justify-center">
        <img src={iconImg} className=" rounded-full w-24  "/>
        </section> */}

        <section className="py-8 container mx-auto flex justify-center">
        <img src={iconImg2} className="w-32 h-24" alt="icon of paua beauty lounge"/>
        </section>
        
        <h1 className=' pb-8 text-4xl font-fontEduNSW font-bold text-stone-500'>Paua Beauty Lounge</h1>
        <p className='font-fontEduNSW font-bold text-xl text-stone-500'>1455 Powell Street <br/> San Francisco, CA 94133 </p>

      </div>


      <div className="p-10 flex justify-center aspect-w-36 aspect-h-16">
        <iframe width="600" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=1455%20Powell%20St,%20San%20Francisco,%20CA%2094133&t=&z=13&ie=UTF8&iwloc=&output=embed" title="This is a unique title" />

        {/* https://www.embedgooglemap.net/  to get the map*/} 
      
      </div>
    </div>
  )
}

export default GoogleMap;