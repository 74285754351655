import React from 'react';
import bgImg from '../assets/facial_01.jpg';

const Dashboard = () => {
  return (
    <div name='home' className='w-full h-[90vh] '>
      <img className='w-full h-full object-cover' src={bgImg} alt="/" />
        
      <div className='max-w-[1200px] m-auto'>
        <div className='absolute bottom-[20%] w-full md:-[50%] max-w-[600px] h-[400px] flex flex-col p-4'>
          <h1 className='pb-3 lg:text-6xl text-4xl font-body01 font-bold text-zinc-50'> Welcome to Paua Beauty Lounge</h1>
          <p className='py-5 text-2xl font-fontQS text-gray-200'>We are an urban salon providing services including mani, pedi, microdermabrasion, micro-bladding and facial. </p>
        </div>
      </div>
    </div>
 
)}

export default Dashboard