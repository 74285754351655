import React from 'react';
import {menu2, menu3, menu4, menu5, menu6, menu7, menu8, menu9} from './pricing';
import maniPic from '../../assets/mani-031.jpg';
import pediPic from '../../assets/pedi_02.jpg';
import faceWax from '../../assets/waxFace_02.jpg';
import waxPic from '../../assets/wax_02.png';


// Facial service
const DisplayData7 = menu7.map((ser) => {
    return(
        <>      
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>
                        <thead className='font-bold text-2xl text-purple-300'>
                            <tr className='text-right'>
                                {ser.serviceName}   
                            </tr>
                        </thead>
                       
                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr className='lg:text-xl'>
                            <th className= 'px-6 py-4'>{sublist.name}</th>
                            <td className= 'px-6 py-4'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
        </>  
    )
})

// MicroBlading
const DisplayData8 = menu8.map((ser) => {
    return(
        <>      
            <div>
                <h2 className='font-fontEduNSW text-center font-bold text-2xl text-rose-200 py-4'>{ser.serviceName}</h2>    
            </div>
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>

                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr className= 'lg:text-xl'>
                            <th className= 'pl-6 py-2'>{sublist.name}</th>
                            <td className= 'px-20 py-2'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
        </>   
    )
})

// Lashes Extension
const DisplayData9 = menu9.map((ser) => {
    return(
        <>      
            <div>
                <h2 className='font-fontEduNSW text-center font-bold text-2xl text-sky-200 py-4'>{ser.serviceName}</h2>    
            </div>
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>

                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr className='lg:text-xl sm:text-lg'>
                            <th className= 'px-6 py-2'>{sublist.name}</th>
                            <td className= 'px-[10%] py-2'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
        </>   
    )
})

//   Popular package  
//const DisplayData = menu1.map((ser) => { 
    // return(     
    //         <>      
    //         <table className='w-full text-left font-fontCaveat text-stone-400 whitespace-nowrap'>
    //                     <thead className='font-bold text-2xl text-purple-300'>
    //                         <tr className='text-right'>
    //                             {ser.serviceName}   
    //                         </tr>
    //                     </thead>
                       
    //                     {ser.links.map((sublist) => ( 
    //                     <tbody>
    //                         <tr className='lg:text-xl'>
    //                         <th className= 'px-6 py-4'>{sublist.name}</th>
    //                         <td className= 'px-6 py-4'>{sublist.price}</td>
    //                         </tr>
    //                     </tbody>
                        
    //                     ))}
    //         </table>      
    //         </>            
 
    // )})

    // Manicure Service
const DisplayData2 = menu2.map((ser) => { 
    return(     
            <> 
            <div>
            <h2 className='font-fontEduNSW text-center py-4 font-bold text-2xl text-red-300'>{ser.serviceName}</h2>     
            </div>      
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>
                        
                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr>
                            <th className= 'px-6 py-3'>{sublist.name}</th>
                            <td className= 'px-6 py-3'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
            </>            
    
    )})
// Pedicure Service
const DisplayData3 = menu3.map((ser) => { 
    return(     
            <>   
            <div className='font-fontEduNSW'>
            <h2 className='text-center py-4 font-bold text-2xl text-red-300'>{ser.serviceName}</h2>     
            </div>   
            <table className='w-full text-center font-fontEduNSW text-stone-400 whitespace-nowrap'>
                     
                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr>
                            <th className= 'px-6 py-4'>{sublist.name}</th>
                            <td className= 'px-6 py-4'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
            </>            
    
    )})

    // Additional service
const DisplayData4 = menu4.map((ser) => { 
    return(     
            <>      
            <div>
                <h2 className='font-fontEduNSW text-center font-bold text-2xl text-purple-300 py-4'>{ser.serviceName}</h2>    
            </div>
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>

                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr>
                            <th className= 'px-6 py-2'>{sublist.name}</th>
                            <td className= 'px-6 py-2'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
            </>            
    
    )})
// Facial Hair Waxing
const DisplayData5 = menu5.map((ser) => { 
    return(     
            <>      
            <div>
                <h2 className='font-fontEduNSW text-center font-bold text-2xl text-purple-300 py-4'>{ser.serviceName}</h2>    
            </div>
            <table className='w-full text-center font-fontEduNSW text-stone-400 whitespace-nowrap'>
        
                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr>
                            <th className= 'px-6 py-3'>{sublist.name}</th>
                            <td className= 'px-6 py-3'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
            </>            
    
    )})
// Body Waxing
const DisplayData6 = menu6.map((ser) => { 
    return(     
            <>      
            <div>
                <h2 className='font-fontEduNSW text-center font-bold text-2xl text-purple-300 py-4'>{ser.serviceName}</h2>    
            </div>
            <table className='w-full text-left font-fontEduNSW text-stone-400 whitespace-nowrap'>
        
                        {ser.links.map((sublist) => ( 
                        <tbody>
                            <tr>
                            <th className= 'px-6 py-3'>{sublist.name}</th>
                            <td className= 'px-6 py-3'>{sublist.price}</td>
                            </tr>
                        </tbody>
                        
                        ))}
            </table>      
            </>            
    
    )})

function Menu(){
    return (
        <div name='services' className='bg-red-50'>
            <div>
                <h1 className='pt-12 text-center font-body01 font-bold text-4xl lg:text-5xl text-stone-500 '>Our Service</h1>
                <p className='pt-8 text-center font-fontQS font-bold text-xl text-stone-400 lg:text-2xl'>*** Menu is subject to change and we reserve the right to refuse service to anyone ***</p>
            </div>

            {/* Facial Service */}
            <div name='otherService' className='md:flex justify-center'>
                <div className='my-20 bg-bgColor bg-center bg-cover lg:w-1/2 w-full rounded-xl shadow-xl'>
                    <div className='sm:p-2 lg:p-10 '>
                        <div className='relative overflow-x-auto bg-white mb-2 p-6 rounded-xl shadow-xl'>
                            {DisplayData7}
                            {DisplayData8}
                            {DisplayData9}
                        </div>
                    </div>  
                </div>
            </div>    


            {/* Popular Package */}    
            {/* <div className='md:flex justify-center'>
                <div className='my-20 bg-bgColor bg-center bg-cover lg:w-1/2 w-full rounded-xl shadow-xl'>
                    <div className='sm:p-2 lg:p-10 '>
                        <div className='relative overflow-x-auto bg-white mb-2 p-6 rounded-xl shadow-xl'>
                            {DisplayData}
                            
                        </div>
                    </div>  
    
                </div>
               
            </div>  end of Popular Package's pricing */}
    
            {/* Manicure service pricing */}
            <div name='mani&pedi' className='lg:p-20'>
                      
                    <div className='bg-bgColor_02 bg-center bg-cover rounded-xl shadow-xl md:flex p-4'>
                        <div className='bg-white border-solid border-8 py-[2%]  border-white md:w-1/2 rounded-l-lg'>
                            <img src={maniPic} alt="Mani Pic" className='rounded-l-lg relative border-neutral-50 w-full h-full object-cover '/> 
                        </div>
                        {/* <img src={maniPic} alt="Picture of Manicure03" className='md:w-1/2 rounded-l-lg border-neutral-50'/>  */}
                        <div className=' flex-1 overflow-x-auto bg-white p-2 rounded-r-lg'>           
                             {DisplayData2}
    
                           
                        </div>
                        
                    </div>
            </div>
    
            {/* Pedicure service pricing */}

            <div className='lg:p-20 '>
                    
                    <div className='bg-red-100 rounded-xl shadow-xl md:flex p-4'>
                    
                        <div className='flex-1 overflow-x-auto bg-white p-2 rounded-l-lg'>
                        {DisplayData3}
                        </div>

                        <div className='bg-white border-solid border-8 border-white md:w-1/2 rounded-r-lg'>
                            <img src={pediPic} alt="Pic of Pedi" className='rounded-r-lg relative border-neutral-50 w-full h-full object-cover'/> 
                        </div>
                        
                    </div>
            </div>

            {/* Additional Service of Mani/Pedi */}
            <div className='md:flex justify-center'>
                <div className=' my-20 bg-bgColor_02 bg-center bg-cover lg:w-1/2 w-full rounded-xl shadow-xl'>
                    <div className='sm:p-5 lg:p-10'>
                        <div className='relative overflow-x-auto bg-white mb-2 p-6 rounded-xl shadow-xl'>
                            {DisplayData4}
                        </div>
                        
                    </div>       
                </div>
            </div>


            {/* Facial Waxing pricing */}
            <div name='waxing' className='lg:p-20'>
                    
                    <div className='bg-bgColor_01 bg-center bg-cover rounded-xl shadow-xl md:flex p-4'>
                       
                        <div className='flex-1 overflow-x-auto bg-white p-2 rounded-l-lg'>
                            {DisplayData5}
                
                        </div>
                       
        
                        <div className='bg-neural-50 border-white border-solid border-8  md:w-1/2 rounded-r-lg '>
                            <img src={faceWax} alt="Pic of waxing" className='rounded-r-lg relative w-full h-full object-cover border-neutral-50'/> 
                        </div>
                        
                    </div>
            </div>

            {/* Body Waxing  */}

            <div className='lg:p-20'>
                    
                    <div className='bg-bgColor_03 bg-center bg-cover rounded-xl shadow-xl md:flex p-4'>
                        <div className='bg-white border-white border-solid border-8 md:w-1/2 rounded-l-lg py-[2%]'>
                            <img src={waxPic} alt="Pic of facial waxing" className='rounded-l-lg relative border-neutral-50 w-full h-full object-cover '/> 
                        </div>
                    
                        <div className='relative flex-1 overflow-x-auto bg-white p-6 rounded-r-lg shadow-xl'>
                            {DisplayData6}
                        
                        </div>
    
                    </div>
            </div>
        </div>
      )
    }


export default Menu