import React from 'react';
import './test.css';

const Gallery = () => {
  return (
    <>
        <div className='bg-gradient-to-br from-slate-300  via-red-50 to-red-100 '>
            <input type="radio" name='Photos' id='check1' checked />
            <input type="radio" name='Photos' id='check2' />
            <input type="radio" name='Photos' id='check3' />
            <input type="radio" name='Photos' id='check4' />
            <input type="radio" name='Photos' id='check5' />
            <input type="radio" name='Photos' id='check6' />
            
            <div className='py-16'>
                
            <div className='xl:container m-auto space-y-16 px-6 text-gray-500 md:px-12'>
                <div>
                    <h2 className=' h2-text-container text-2xl font-bold text-center text-gray-400 font-fontEduNSW dark:text-white md:text-4xl'> 
                        Gallery
                    </h2>
                    {/* <div className='top-content'>
                        <h3 className =''>Photo Gallery</h3>
                        <label className ='' for="check1">All Photos</label>
                        <label className ='' for="check2">Mani</label>
                        <label className ='' for="check3">Dip</label>
                        <label className ='' for="check33">Gel X</label>
                        <label className ='' for="check4">Microblading</label>
                        <label className ='' for="check5">Microchanneling</label>
                    </div> */}

                </div>

                <div className='photo-gallery md:-mx-8 grid lg:gap-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
                    <div className="pic all-photos group relative rounded-3xl  dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent 
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                        {/* <div className='relative pic all-photos'> */}
                            <img className='rounded-2xl'  src={require('../../assets/img/pauaSalon.jpg')} alt="salon pic" />
                        {/* </div> */}
                    </div>

                    <div className="pic mani group relative bg-white rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                        {/* <div className='relative pic mani'> */}
                            <img className='rounded-2xl' src={require('../../assets/img/mani-031.jpg')} alt="manipic" /> 
                        {/* </div> */}
                    </div>

                    <div className="pic microblading group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">                  
                       <img className='rounded-2xl'src='https://scontent-atl3-2.xx.fbcdn.net/v/t39.30808-6/459672123_18057230464696557_1107454540027621619_n.jpg?stp=dst-jpegr&_nc_cat=104&ccb=1-7&_nc_sid=127cfc&_nc_ohc=hHoZTLZTFeUQ7kNvgHLZ01U&se=-1&_nc_ht=scontent-atl3-2.xx&oh=00_AYCRrP0NwdNK9sbJ1wCQ5eIG8v_p5busNvBormv6mPfeXQ&oe=66EE7DD8' alt="mani pic" />    
                    </div>

                    <div className="pic gel-x group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl' src={require('../../assets/img/gelxchrome.jpg')} alt="gel-x pic" />                         
                    </div>

                    <div className="pic microblading group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/facialads_1.jpg')} alt="facial ads pic" />                         
                    </div>

                    <div className="pic gel-x group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nailchrome.jpg')} alt="gel-x pic" />                         
                    </div>

                    <div className="pic gel-x group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/pinkgelx.jpg')} alt="gel-x pic" />                         
                    </div>

                    <div className="pic gel-x group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/naildesign.jpg')} alt="gel-x pic" />                         
                    </div>

                    <div className="pic microblading group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mb-01.jpg')} alt="microblading pic" />                         
                    </div>

                    {/* <div className="pic michanneling group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mc-01.jpg')} alt="michanneling pic" />                         
                    </div> */}
{/* 
                    <div className="pic michanneling group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mc-02.jpg')} alt="michanneling pic" />                         
                    </div> */}

                    {/* <div className="pic michanneling group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mc-03.jpg')} alt="michanneling pic" />                         
                    </div> */}

                    {/* <div className="pic michanneling group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mc-04.jpg')} alt="michanneling pic" />                         
                    </div> */}
{/* 
                    <div className="pic michanneling group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mc-05.jpg')} alt="michanneling pic" />                         
                    </div> */}

                    <div className="pic mani group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nail-02.jpg')} alt="mani pic" />                         
                    </div>

                    <div className="pic mani group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nail-03.jpg')} alt="mani pic" />                         
                    </div>

                    <div className="pic mani group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nail-04.jpg')} alt="mani pic" />                         
                    </div>

                    <div className="pic mani group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nails-02.jpg')} alt="mani pic" />                         
                    </div>

                    <div className="pic mani group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/nailsart-01.jpg')} alt="mani pic" />                         
                    </div>

                    <div className="pic microblading group relative rounded-3xl dark:border-gray-700 dark:bg-gray-800
                     dark:lg:bg-transparent lg:border-transparent hover:border-gray-100 dark:shadow-none
                     dark:hover:border-gray-700 dark:hover:bg-gray-800 shadow-2xl shadow-gray-600/10 lg:shadow-transparent
                     lg:hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300">
                       
                       <img className='rounded-2xl'
                       src={require('../../assets/img/mb-02.jpg')} alt="microblading pic" />                         
                    </div>



                    
                </div>
            </div>
            </div>
        
        </div>
    </>
    
  )
}

export default Gallery