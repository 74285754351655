import React, {useRef} from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from '@emailjs/browser';

const Footer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_5g6rutr', 'template_nsk9evq', form.current, 'h0TZy65oecHzvF4QS')
      .then((result) => {
          console.log(result.text);
          console.log("Message Sent Success");
          e.target.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
      <div name='contact'>
        <footer className="bg-gray-100 lg:text-left ">
          <div className="container p-10 text-gray-800 ">
            <div className="grid lg:grid-cols-2 gap-4 p-5 ">

              <div className="mb-6 md:mb-0">
                <h1 className="pb-6 text-stone-500 text-3xl font-fontCeveat uppercase">Contact Us</h1>
                
                <h5 className='mt-4 mb-2 text-xl font-fontEduNSW font-extrabold text-stone-500'>Business Hours</h5>
                <h5 className='py-1 font-fontCaveat text-stone-400'>Monday: 
                <span className='font-fontCaveat font-light text-stone-500'>{'  '}10:00 a.m - 7:00 p.m</span> 
                </h5>

                <h5 className='py-1 font-fontCaveat text-stone-400'>Tuesday:
                <span className='font-fontCaveat font-light text-stone-500'> {'  '}Closed</span>
                </h5>

                <h5 className='py-1 font-fontCaveat text-stone-400'>Wednesday-Sunday: 
                <span className='font-fontCaveat font-light text-stone-500'>{'  '}10:00 a.m - 7:00 p.m</span> 
                </h5>

                {/* Address */}
                <div className='mt-8 text-stone-500 text-xl font-fontEduNSW font-extrabold '> Address </div>
                <p className='mt-2 font-fontCaveat text-xl text-stone-400'>1455 Powell Street <br/> San Francisco, CA 94133</p>

                
                {/* Phone  */}
                <div className='mt-8 text-stone-500 text-xl font-fontEduNSW font-extrabold'> Phone </div>
                <p className='mt-2 font-fontCaveat text-xl text-stone-400'>(415)-525-4766 </p>
                
                {/* Email */}
                <div className='mt-8 text-stone-500 text-xl font-fontEduNSW font-extrabold'> Email </div>
                {/* <p className='mt-4 font-fontCaveat text-xl text-stone-400'>beautypaua@gmail.com </p> */}
                < a href="mailto:beautypaua@gmail.com" className=' font-fontEduNSW text-xl text-stone-400 underline'>pauabeautylounge@gmail.com</a>
                    
              </div>

                {/* right side of footer leave us a message */}
              <div className="mb-6 md:mb-0">
                <form ref={form} onSubmit={sendEmail}>
                <label for="name" className="mt-6 block mb-2 text-xl font-medium text-gray-900">Your name</label>
                <input type="username" name="user_name" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5" placeholder="name"/>

                <label for="email" class="mt-8 block mb-2 text-xl font-medium text-gray-900">Your email</label>
                <input type="email" name="user_email" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 " placeholder="name@abc.com"/>

                <label for="phoneNo" class="mt-8 block mb-2 text-xl font-medium text-gray-900">Your phone number</label>
                <input type="phoneNo" name="user_phoneNo" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[80%] p-2.5 " placeholder="(123) 123-1234"/>
        
                
                <label for="message" className="mt-8 block mb-2 text-xl font-medium text-gray-900">Your message</label>
                <textarea name="message" rows="4" className="block p-2.5 w-[80%] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500" placeholder="Leave a comment..."></textarea>

                <div className=' grid justify-end mr-[20%]'> 
                  <button type="submit" value='Send' className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-4">Submit</button>
                </div> 
                </form>
              </div>


            </div>
          </div>

            {/* Copyright and designer */}
          <div className="text-center text-gray-700 p-4">
              All Rights Reserved © Paua Beauty Lounge 2022 - 2024 | Designed by Yenni Lam
          
          </div>
      </footer>

    </div>
)
}

export default Footer