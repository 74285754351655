import React from 'react'

const Mission = () => {
  return (
    <>
    <div className='lg:pl-20 bg-orange-50 '> 
        <div className='row mt-10 p-10'>
            <div className='col-9'>
            <h1 className=' text-2xl font-fontSatisfy font-bold text-stone-400 '>Our Mission</h1>
            <h1 className='pt-3 text-3xl font-fontKalam font-bold text-stone-400 '>Sanitation</h1>
            </div>
            <div className='col-8 md:col-4 lg:col-4'>
                <p className='pt-3 lg:text-lg font-fontKalam sm:text-sm text-stone-500'>
                    At Paua Beauty Lounge, we take health very seriously. Our top concern is sanitation. 
                    We use all DISPOSABLE TOOLS wherever possible. We only use non-toxic products on the market. 
                    No acrylics or unsafe nail applications here, just pure and clean services. 
                    All of our nail technicians are experienced, licensed and trained by Paua management.
                    In response to the need to enforce more stringent hygiene protocols to prevent the transmission of pathogens,
                    especially the SARS-CoV-2 virus, we have installed protective shields or barriers at all of our salon tables.
                </p>
                
                <p className='lg:text-lg font-fontKalam sm:text-sm text-stone-500'>
                    We go above and beyond what is required of us from the state and Cosmetology Board. 
                    We're proud that we've set the bar so high. Our implements are sanitized with hospital grade disinfectant and 
                    UV Sterilized. We clean each pedicure station immediately after use and provide new disposable liners for each 
                    client. Rest assured, your safety is our top priority.

                </p>
            </div>
           
        </div>
    </div>
    </>
  )
}

export default Mission